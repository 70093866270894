<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="dataList" :max-items="table.length"
      :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th sort-key="sku_code">SKU Code</vs-th>
        <vs-th sort-key="item_name">Item Name</vs-th>
        <vs-th sort-key="warehouse">Warehouse</vs-th>
        <vs-th sort-key="sn_batch">SN/Batch</vs-th>
        <vs-th sort-key="exp_date">Exp Date</vs-th>
        <vs-th sort-key="unit">Unit</vs-th>
        <vs-th sort-key="amount">Amount</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].sku_code">
            {{ data[indextr].sku_code }}
          </vs-td>
          <vs-td :data="data[indextr].item_name">
            {{ data[indextr].item_name }}
          </vs-td>
          <vs-td :data="data[indextr].warehouse">
            {{ data[indextr].warehouse }}
          </vs-td>
          <vs-td :data="data[indextr].sn_batch">
            {{ data[indextr].sn_batch }}
          </vs-td>
          <vs-td :data="data[indextr].exp_date">
            {{ dateFormat(data[indextr].exp_date) }}
          </vs-td>
          <vs-td :data="data[indextr].unit">
            {{ data[indextr].unit }}
          </vs-td>
          <vs-td :data="data[indextr].amount">
            {{ data[indextr].amount }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex/dist/vuex.common.js";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startDocDate: {
      type: Date,
    },
    endDocDate: {
      type: Date,
    },
    startDueDate: {
      type: Date,
    },
    endDueDate: {
      type: Date,
    },
    openKeyDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ getStockTerritoryReport: 'reportStockTerritory/getStockTerritoryReport',
      generateStockTerritoryReport: 'reportStockTerritory/generateStockTerritoryReport'
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleExport() {
            this.$vs.loading();
        this.generateStockTerritoryReport(
        {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory: this.territoryIDs,
        }
      ).then(() => {
        this.$vs.loading.close();
      })
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getStockTerritoryReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory: this.territoryIDs,
        }).then(() => {
          this.$vs.loading.close();
        }).catch(() => {
          this.$vs.loading.close();
        })
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    dataList(val) {
            this.table.start = ((this.table.page - 1) * this.table.length) + 1
            this.table.end = ((this.table.page - 1) * this.table.length) + val.length
    },
    generateReport(val) {
            if (val && val.status === 'OK') {
                this.$vs.notify({
                    color: "success",
                    title: "Processing",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
             }
    },
    totalRecord(val) {
            this.table.total = val
    },
  },
  computed: {
    ...mapState({
      dataList: (state) => state.reportStockTerritory.dataList,
      totalPage: (state) => state.reportStockTerritory.total_page,
      totalRecord: (state) => state.reportStockTerritory.total_record,
      generateReport: (state) => state.reportStockTerritory.generateReport,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>